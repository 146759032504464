import React from 'react';
import { Link } from 'gatsby';

import { BsCheckCircle } from 'react-icons/bs';

function Whiteboard({ text }: { text: string[] }) {
  return (
    <div className="bg-white md:h-full text-contrast border-2 border-contrast rounded-lg px-4 md:px-8 pt-8 pb-12 md:py-12 nanum-pen shadow-lg relative">
      <div className="text-center absolute -right-4 -bottom-16  md:-top-8 xl:-right-8 h-28 w-28 md:h-32 md:w-32 rounded-full text-white bg-contrast shadow-xl flex items-center justify-center flex-col">
        <span className="leading-[1rem] text-xl inline-block">1 Hour Lesson</span>
        <span className="inline-block text-3xl whitespace-nowrap tracking-tighter">From £25</span>
      </div>
      <ul>
        {text.map((t, i) => (
          <li
            key={`${t}-whiteboard`}
            className={`text-3xl lg:text-4xl ${
              i !== text.length - 1 ? 'mb-4' : ''
            } flex items-center justify-items-start`}
          >
            <BsCheckCircle className="w-1/12 inline-block p-1 mr-2" />
            <span className="w-11/12 inline-block leading-7">{t}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function Hero() {
  return (
    <div className="bg-primary text-white py-4 md:py-12 shadow-lg md:overflow-x-hidden">
      <div className="max-w-screen-xl mx-auto flex md:flex-row flex-col space-around px-4 md:px-8">
        <div className="text-center md:text-left md:w-1/2 mb-5 md:mb-0 md:pr-8">
          <h1 className="text-3xl md:text-4xl xl:text-6xl font-bold mb-2 md:mb-4">
            Reliable private tutor in Bournemouth
          </h1>
          <p className="md:text-xl lg:text-2xl mb-4 md:mb-8">
            Online one-to-one or group tutoring for those looking for support in educational skills
          </p>
          <div className="w-full flex justify-between">
            <Link
              to="/contact/"
              className="hero-button p-2 bg-contrast transition ease-in-out duration-500 hover:bg-white hover:text-contrast border-2 border-contrast rounded-lg text-white flex items-center justify-center text-center"
            >
              Request a Consultation
            </Link>
            <Link
              to="/learning/"
              className="hero-button p-2 bg-white text-contrast border-2 border-contrast rounded-lg transition ease-in-out duration-500 hover:bg-contrast hover:text-white flex items-center justify-center text-center"
            >
              How I Can Help
            </Link>
          </div>
        </div>
        <div className="md:w-1/2">
          <Whiteboard
            text={[
              'Ages 8 - adult',
              '11+ preparation and common entrance exams',
              'Catch up and accelerated progress',
              'Functional skills and fundamental Maths and English skills for teachers',
              'GCSE tutoring £30 per 1 hour lesson',
              'All other levels £25 per 1 hour lesson'
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
