import React, { ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Header from './header/header';
import Hero from './hero';

function Heading({ heading, subheading }: { heading: string; subheading: string }) {
  return (
    <div className="w-full text-center md:text-left bg-primary text-white shadow-lg">
      <div className="max-w-screen-xl text-center mx-auto px-4 py-2 md:py-6">
        <h1 className="page-heading mb-1 md:mb-2 text-3xl md:text-5xl">{heading}</h1>
        <p className="md:text-xl">{subheading}</p>
      </div>
    </div>
  );
}

function Layout({
  children,
  page = null,
  heading,
  subheading,
}: {
  children: ReactNode;
  page?: string | null;
  heading: string;
  subheading: string;
}) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteTitle = data.site.siteMetadata?.title || 'Title';

  return (
    <>
      <div className="min-h-screen">
        <Header />
        {heading && <Heading heading={heading} subheading={subheading} />}
        {page && page === 'home' && <Hero />}
        <main className="max-w-screen-xl mx-auto px-4 md:px-8">{children}</main>
      </div>
      <footer className="text-white w-full py-8 bg-primary relative">
        <div className="max-w-screen-xl mx-auto flex flex-col items-center mb-4 md:mb-0">
          <div className="w-full flex flex-col md:flex-row items-center justify-between px-4">
            <Link to="/" className="mb-2 md:mb-0">
              <StaticImage
                src="../images/logo-text-white.png"
                width={150}
                layout="constrained"
                quality={95}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="One to One logo"
              />{' '}
            </Link>
          </div>
        </div>
        <span className="inline-block text-sm text-center w-full md:w-auto md:absolute md:bottom-2 md:left-1/2 md:transform md:-translate-x-1/2">
          © {new Date().getFullYear()} {siteTitle}
        </span>
      </footer>
    </>
  );
}

export default Layout;
