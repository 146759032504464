import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import useOnClickOutside from '../../utils/useOnClickOutside';
import Hamburger from './hamburger';
import NavMenu from './navMenu';

function Header() {
  const [viewMenu, setViewMenu] = useState(false);

  const ref = useRef<HTMLElement>(null);

  useOnClickOutside(ref, () => setViewMenu(false));

  const navLinks = ['Home', 'About', 'Learning', 'FAQ'];

  return (
    <header ref={ref} className="py-4 md:pt-6 md:pb-0 bg-primary text-white relative">
      <nav className="px-4 md:px-8 relative flex md:flex-row flex-wrap items-center justify-between max-w-screen-xl mx-auto">
        <Link className="text-2xl w-36 mx-auto md:mx-0 md:w-auto" to="/">
          <StaticImage
            src="../../images/logo-text-white.png"
            width={200}
            layout="constrained"
            quality={100}
            placeholder="blurred"
            formats={['auto', 'webp', 'avif']}
            alt="One to One logo"
          />
        </Link>
        <Hamburger setViewMenu={setViewMenu} viewMenu={viewMenu} />
        <NavMenu viewMenu={viewMenu} navLinks={navLinks} />
      </nav>
    </header>
  );
}

export default Header;
