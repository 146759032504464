import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';

import { Underline } from '../underline';

function NavLink({ to, label }: { to: string; label: string }) {
  const windowGlobal = typeof window !== 'undefined';

  const [pathName, setPathName] = useState<string | null>(null);
  const [width, setWidth] = useState<number | null>(null);
  const [pathLength, setPathLength] = useState<number | null>(null);
  const [showUnderline, setShowUnderline] = useState(false);

  const linkRef = useRef<HTMLAnchorElement>(null);
  const pathRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    if (!windowGlobal) return;

    setPathName(window.location.pathname);
  }, []);

  useEffect(() => {
    if (!linkRef.current) return;
    const newWidth = linkRef.current.offsetWidth - 10;
    setWidth(newWidth);

    if (pathName === to) {
      setShowUnderline(true);
    }
  }, [pathName]);

  useEffect(() => {
    if (!pathRef.current) return;
    if (!pathLength) {
      const newPathLength = pathRef.current.getTotalLength();
      setPathLength(newPathLength);
    }
  }, [pathRef, showUnderline]);

  return (
    <Link
      onMouseEnter={() => (pathName === to ? null : setShowUnderline(true))}
      onMouseLeave={() => (pathName === to ? null : setShowUnderline(false))}
      ref={linkRef}
      className="block p-2 border-b-2 border-white md:border-none transition ease-in-out duration-500 hover:text-contrast relative"
      to={to}
      activeClassName="active-link"
    >
      <span className="block md:text-center text-2xl nanum-pen">{label}</span>
      {width && (
        <Underline
          pathRef={pathRef}
          showUnderline={showUnderline}
          className={`${
            showUnderline ? 'opacity-100' : 'opacity-0'
          } hidden -mt-1 md:block transition duration-500 ease-in-out`}
          width={width}
          strokeDasharray={pathLength}
          strokeDashoffset={showUnderline ? 0 : pathLength}
        />
      )}
    </Link>
  );
}

function Contact() {
  return (
    <Link
      to="/contact/"
      className="text-white inline-block w-full md:w-auto text-center bg-contrast border-2 border-contrast hover:text-contrast hover:bg-white py-2 px-4 rounded mt-2 md:mt-0 md:ml-1 transition ease-in-out duration-500"
    >
      Contact
    </Link>
  );
}

function NavMenu({ viewMenu, navLinks }: { viewMenu: boolean; navLinks: string[] }) {
  return (
    <div
      className={`${
        viewMenu ? 'absolute' : 'hidden'
      } z-10 md:z-0 md:static bg-primary md:bg-transparent top-full md:top-auto left-0 md:left-auto w-full md:w-auto md:flex items-center text-right mt-4 md:mt-0 border-t-2 border-b-2 pb-2 md:pb-0 px-2 md:px-0 border-white md:border-none md:ml-auto`}
    >
      {navLinks.map(l => (
        <NavLink key={`${l}-link`} to={l === 'Home' ? '/' : `/${l.toLowerCase()}/`} label={l} />
      ))}
      <Contact />
    </div>
  );
}

export default NavMenu;
