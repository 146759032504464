import React, { useState, useEffect, useRef, RefObject, ReactNode } from 'react';

type IProps = {
  width: number;
  className?: string;
  pathRef?: RefObject<SVGPathElement>;
  strokeDasharray?: number;
  strokeDashoffset?: number;
};

export function Underline({ width, className, pathRef, strokeDasharray, strokeDashoffset }: IProps) {
  const [path, setPath] = useState<string | null>(null);

  useEffect(() => {
    const moveYmin = 2;
    const moveYMax = 9;

    const curveXMin = 10;
    const curveXMax = width;
    const curveYMin = 2;
    const curveYMax = 9;

    const endYMin = 2;
    const endYMax = 8;

    const moveY = Math.floor(Math.random() * (moveYMax - moveYmin)) + moveYmin;
    const curveX = Math.floor(Math.random() * (curveXMax - curveXMin)) + curveXMin;
    const curveY = Math.floor(Math.random() * (curveYMax - curveYMin)) + curveYMin;
    const endY = Math.floor(Math.random() * (endYMax - endYMin)) + endYMin;

    const newPath = `M2 ${moveY} Q ${curveX} ${curveY} ${width} ${endY}`;

    setPath(newPath);
  }, []);

  if (!path) return null;

  return (
    <svg className={className || ''} width={width} height="10" xmlns="http://www.w3.org/2000/svg">
      {/* Path ref, strokeDashArray and strokeDashOffset combine to animate line on hover under nav links */}
      <path
        ref={pathRef || null}
        style={{ transition: 'stroke-dasharray 0.5s, stroke-dashoffset 0.5s' }}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        d={path}
        stroke="#E04E29"
        fill="transparent"
        strokeWidth="2px"
        strokeLinecap="round"
      />
    </svg>
  );
}

function UnderlineHeading({
  children,
  marginBottom,
  align,
}: {
  children: ReactNode;
  marginBottom: string;
  align: string;
}) {
  const [width, setWidth] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const newWidth = ref.current ? ref.current.offsetWidth : 0;
    setWidth(newWidth);
  }, []);

  return (
    <div ref={ref} className={`${marginBottom || ''} w-max ${align || ''}`}>
      {children}
      {width && <Underline width={width} />}
    </div>
  );
}

export default UnderlineHeading;
