import React, { Dispatch, MouseEventHandler, SetStateAction } from 'react';

function MenuSVG({ display }: { display: 'hidden' | 'block' }) {
  return (
    <svg
      className={`h-6 w-6 ${display}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
    </svg>
  );
}

function CloseMenuSVG({ display }: { display: 'hidden' | 'block' }) {
  return (
    <svg
      className={`h-6 w-6 ${display}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
  );
}

function Hamburger({ setViewMenu, viewMenu }: { setViewMenu: Dispatch<SetStateAction<boolean>>; viewMenu: boolean }) {
  const handleClick: MouseEventHandler = e => {
    e.stopPropagation();
    setViewMenu(!viewMenu);
  };
  return (
    <div className="absolute right-4 top-4 flex h-6 w-6 md:hidden cursor-pointer">
      <button id="hamburger" type="button" aria-label="menu button" onClick={handleClick}>
        <MenuSVG display={viewMenu ? 'hidden' : 'block'} />
        <CloseMenuSVG display={viewMenu ? 'block' : 'hidden'} />
      </button>
    </div>
  );
}

export default Hamburger;
